import React from 'react';
import Layout from '../layouts/Layout'
import SiteMetadata from '../components/SiteMetadata';
import ContactForm from '../components/ContactForm'

const ContactPage = ({location}) => {
    return (
        <Layout path={location.pathname} >
            <SiteMetadata
                title="Made By Rise Project"
                description="Contact Form for MBR"
            />
            <div className="container pb-32 pt-32">
                <div className="lg:w-1/2">
                    <h2 className="text-mbr_orange my-4">Let's talk</h2>
                    <p className="large">
                    Made by rise would d love to find out how we can help you get online and get more business. <br/><br/>

                    For all inquiries, questions, portfolio and rates, feel free to contact us.
                    </p>
                    <ContactForm />
                </div>
                
            </div>
        </Layout>
    );
};

export default ContactPage;