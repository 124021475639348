import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Recaptcha from "react-google-recaptcha"
import Popup from "reactjs-popup"
import { BsExclamationCircle } from "react-icons/bs"

// Recaptcha public key
const SITE_RECAPTCHA_KEY = "6LdyamMcAAAAALazrjI6ANfCIKq-c8VCO7I8qwKP"

// form label and input style
const inputContainerStyle = "group mb-4 flex flex-col text-normal"
const labelStyle = "group-focus:font-extrabold"
const inputStyle =
  "px-4 py-2 mt-2 mb-4 rounded-md border border-black focus:border-2 appearance-none focus:outline-none focus:font-bold"

const ContactForm = () => {
  const [state, setState] = useState({})
  // With this the button is disabled by default, but on Recaptcha change, the form can be submitted
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [msgWarning, setMsgWarning] = useState(false)
  const [msgWarningStyle, setMsgWarningStyle] = useState("")

  // For submission modal
  const [open, setOpen] = useState(false)
  const closeModal = () => setOpen(false)

  // Reset form after open state change
  useEffect(() => {
    document.getElementById("myForm").reset()
  }, [open])

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const recaptchaRef = React.createRef() // new Ref for reCaptcha

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current.getValue()

    if (!state.message) {
      setMsgWarning(true)
      setMsgWarningStyle("text-mbr_orange border-mbr_orange")
    }

    if (state.message) {
      const encode = data => {
        return Object.keys(data)
          .map(
            key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
          )
          .join("&")
      }

      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          "g-recaptcha-response": recaptchaValue,
          ...state,
        }),
      })
        .then(() => setOpen(o => !o))
        .catch(error => alert(error))
    }
  }

  return (
    <div className="w-full h-full my-20">
      <form
        className="flex flex-col"
        id="myForm"
        name="Contact Form"
        method="POST"
        data-netlify="true"
        data-netlify-recaptcha="true"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="form-name" value="Contact Form" />
        <div className={`${inputContainerStyle}`}>
          <label className={`${labelStyle}`} for="name">
            Your name
          </label>
          <input
            name="name"
            className={`${inputStyle}`}
            type="text"
            name="name"
            id="name"
            onChange={handleChange}
            required
          />
        </div>
        <div className={`${inputContainerStyle}`}>
          <label className={`${labelStyle}`} for="email">
            Your email
          </label>
          <input
            className={`${inputStyle}`}
            type="email"
            name="email"
            id="email"
            onChange={handleChange}
            required
          />
        </div>
        <div className={`${inputContainerStyle}`}>
          <div className="flex">
            <label className={`${labelStyle} ${msgWarningStyle}`} for="message">
              Message
            </label>
            {msgWarning ? (
              <div className={`flex ${msgWarningStyle}`}>
                <BsExclamationCircle className="ml-8 mr-2 h-auto fill-current" />
                <p className="small">Please enter a message.</p>
              </div>
            ) : null}
          </div>
          <textarea
            className={`${inputStyle} ${msgWarningStyle}`}
            name="message"
            id="message"
            rows="6"
            onChange={handleChange}
          />
          <Recaptcha
            className="my-1"
            ref={recaptchaRef}
            sitekey={SITE_RECAPTCHA_KEY}
            size="normal"
            id="recaptcha-google"
            onChange={() => setButtonDisabled(false)} // disable the disabled button!
          />
        </div>

        <p className="text-xs mb-4">
          We take your privacy seriously. For more information see our{" "}
          <Link className="underline" to="/privacy">
            Privacy Policy
          </Link>
          .
        </p>
        <button
          className="rounded px-12 h-20 border-2 border-mbr_blue bg-mbr_blue w-full sm:w-fitcontent"
          type="submit"
          disabled={buttonDisabled}
        >
          <div className="text-mbr_pale flex items-center sm:w-auto w-full justify-between">
            <h4 className="mr-4">Send</h4>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.4382 11.1843L11.7034 0.13146C11.6056 0.0471908 11.4809 0 11.3494 0H8.36629C8.11685 0 8.00225 0.310112 8.19101 0.47191L19.9955 10.7191H0.269663C0.121348 10.7191 0 10.8404 0 10.9888V13.0112C0 13.1596 0.121348 13.2809 0.269663 13.2809H19.9921L8.18764 23.5281C7.99888 23.6933 8.11348 24 8.36292 24H11.4472C11.5112 24 11.5753 23.9764 11.6225 23.9326L24.4382 12.8157C24.5549 12.7143 24.6485 12.5889 24.7126 12.4482C24.7767 12.3075 24.8099 12.1546 24.8099 12C24.8099 11.8454 24.7767 11.6925 24.7126 11.5518C24.6485 11.4111 24.5549 11.2857 24.4382 11.1843Z"
                fill="#F3F5F0"
              />
            </svg>
          </div>
        </button>
        <Popup open={open} closeOnDocumentClick onClose={closeModal}>
          <div className="modal rounded text-mbr_pale bg-mbr_blue">
            <div className="text-center px-4 py-10">
              <h2>Thanks! We will get back to you soon!</h2>
              <div
                className="bg-customsandal py-2 px-4 w-fit mx-auto mt-8"
                onClick={closeModal}
              >
                <Link to="/">Back to Home</Link>
              </div>
            </div>
          </div>
        </Popup>
      </form>
    </div>
  )
}

export default ContactForm
